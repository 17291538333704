import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {DESTINATION_DOMAIN_ADDRESS} from "../../utils/constants";

export const getRemainSeason = createAsyncThunk("season/remain", async () => {
    const axiosResponse = await axios
      .get(`${DESTINATION_DOMAIN_ADDRESS}`)
      .then((res) => res.data.data)
      .catch((err) => {
        console.log(err)
      });

    return axiosResponse
  }
)

export const remainSeasonSlice = createSlice({
  name: "remainSeason",
  initialState: {
    contents: [],
    status: null,
  },
  reducers: {},
  extraReducers: {
    [getRemainSeason.pending]: (state, action) => {
      state.status = "loading";
    },
    [getRemainSeason.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.contents = action.payload;
    },
    [getRemainSeason.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    }
  }
});

export const remainSeasonReducer = remainSeasonSlice.reducer;