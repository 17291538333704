import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {DESTINATION_DOMAIN_ADDRESS} from "../../utils/constants";

export const loginRequest = createAsyncThunk(
  "signUp/request",
  async ({email, password}) => {
    return await axios
      .post(`${DESTINATION_DOMAIN_ADDRESS}/user/login`, {
        email: email,
        password: password
      })
      .then((res) => res.data)
      .catch((err) => err.response.data)
  }
);


export const loginRequestSlice = createSlice({
    name: "loginRequest",
    initialState: {
      content: [],
      status: null,
    },
    reducers: {},
    extraReducers: {
      [loginRequest.pending]: (state, action) => {
        state.status = "loading";
      },
      [loginRequest.fulfilled]: (state, action) => {
        state.status = "succeeded";
        state.content = action.payload;
      },
      [loginRequest.rejected]: (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      },
    }
  }
);
export const loginSliceReducer = loginRequestSlice.reducer