import {Button} from "react-bootstrap";

const InputPassword = (
  {titleName, placeHoldName, onChangePassword, isMobile}
) => {
  return (
    <div className="mt-4 mb-2">
      <div style={{fontWeight: "normal", fontSize: isMobile ? "16px" : "18px", marginBottom: "5px",}}>{titleName}</div>
      <input style={{
        fontSize: isMobile ? "13px" : "16px"
      }}
             onChange={onChangePassword} type="password" className="form-control" id="address"
             placeholder={placeHoldName} required/>
    </div>
  )
}
export default InputPassword;