import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {DESTINATION_DOMAIN_ADDRESS} from "../../utils/constants";

/**
 * 조회수 업데이트 API
 * */
export const asyncUpdateViewBoardSlice = createAsyncThunk(
  "board/update",
  async ({boardId}) => {
    return await axios
      .post(`${DESTINATION_DOMAIN_ADDRESS}/board/${boardId}/view`,)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err)
      })
  }
);

export const updateViewBoardSlice = createSlice({
    name: "freeBoard/update",
    initialState: {
      content: [],
      status: null,
    },
    reducers: {},
    extraReducers: {
      [asyncUpdateViewBoardSlice.pending]: (state, action) => {
        state.status = "loading";
      },
      [asyncUpdateViewBoardSlice.fulfilled]: (state, action) => {
        state.status = "succeeded";
        state.content = action.payload.content;
      },
      [asyncUpdateViewBoardSlice.rejected]: (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      },
    }
  }
)

export const updateViewBoardReducer = updateViewBoardSlice.reducer
