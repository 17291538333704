import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import sty from "@emotion/styled";
import Badge from 'react-bootstrap/Badge';

import StyledDesktopWrapper from "../../components/wrapper/StyledDesktopWrapper";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getFreeBoardSlice} from "../../redux/board/freeBoardSlice";
import {Grid} from "@mui/material";
import {StyledButtonWrapper} from "../../components/wrapper";
import useLocalStorage from "../../hooks/useLocalStorage";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const FreeDesktop = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const freeBoard = useSelector((store) => store.freeBoardRequest);
  const freeBoardContent = freeBoard.content.content;
  const [page, setPage] = useState(1)

  useEffect(() => {
    dispatch(getFreeBoardSlice({size: 5, page: page - 1}))
  }, [page])

  const [userInfo] = useLocalStorage("userInfo", []);

  const BoardList = ({boardData}) => {
    return (
      (boardData.deleted === false) ?
        <Box sx={{minWidth: 275}}>

          <Card variant="outlined">
            <div onClick={() => {
              navigate(`/board/free/${boardData.id}`);
            }} style={{maxWidth: "50%", cursor: "pointer",}}>
              <React.Fragment>
                <CardContent>
                  <Typography sx={{
                    fontSize: 18, display: "flex", alignItems: "center"
                  }} gutterBottom>
                    {boardData.nickName}
                    <Badge style={{fontSize: 11, marginLeft: "7px"}} bg="secondary">{boardData.clanName}</Badge>
                  </Typography>
                  <div style={{fontSize: 13, color: "gray", marginBottom: "10px"}}>
                    <span style={{fontSize: "12px"}}>{boardData.createdAt}</span>
                    <span style={{marginLeft: "15px", fontSize: "12px"}}>👀 {boardData.view}</span>
                  </div>
                  <Typography variant="h6" component="div">
                    {boardData.title}
                  </Typography>
                  <br/>
                  <Typography variant="body2" color="text.secondary">
                    <span style={{fontSize: "15px"}}>👍 {boardData.good}</span>
                    <span style={{marginLeft: "15px", fontSize: "15px"}}>👎 {boardData.bad}</span>
                  </Typography>
                </CardContent>
              </React.Fragment>
            </div>
          </Card>
        </Box> :
        <Box sx={{minWidth: 100}}>
          <Card variant="outlined">
            <CardContent>
              <Typography style={{color: "#d3d3d3"}} component="div">
                삭제된 글이에요 ❌
              </Typography>
              <br/>
            </CardContent>
          </Card>
        </Box>
    )
  }


  const navigateWirePage = () => {
    if (userInfo.length === 0) {
      alert("로그인 후 글 작성이 가능해요 😎");
    } else {
      navigate(`/board/write`);
    }
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  return (<div>
    <StyledDesktopWrapper>
      <BoardForm>
        {/*상단 부분*/}
        <Grid container spacing={2}>
          {/* 오른족*/}
          <Grid item xs={9}>
            <h4 style={{fontWeight: "bold"}}>포유 게시판 💘</h4>
          </Grid>
          {/* 왼쪽*/}
          <Grid style={{textAlign: "right"}} item xs={3}>
            <StyledButtonWrapper
              onClick={navigateWirePage}
              justifyContent={"center"}
              height={"30px"}
              style={{
                width: "100px",
                alignItems: "center",
                background: "#775ee1",
                color: "white",
                fontSize: "15px",
                cursor: "pointer",
                borderRadius: "20px"
              }}
            >글쓰기
            </StyledButtonWrapper>
          </Grid>
        </Grid>
        <hr style={{width: "100%", marginTop: "20px", marginBottom: "40px"}}/>
        {(freeBoardContent || []).map((boardData, index) => (<BoardList
          boardData={boardData}
          index={index}
        />))}

        <Stack>
          <Pagination
            size="large"
            page={page}
            onChange={handleChange}
            count={freeBoard.content.totalPages} color="secondary"/>
        </Stack>
      </BoardForm>
    </StyledDesktopWrapper>
  </div>);
}

const BoardForm = sty.div`
        width: 100%;
        max-width: 800px;
        padding: 60px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.50)
        `

export default FreeDesktop;