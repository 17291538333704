import React from "react";
import styled from "@emotion/styled";
import {Route, Routes} from "react-router";
import {
  HomePage,
  UserPage,
  PrivatePage,
  ClanPage,
  LeaguePage,
  RecordPage,
} from "./views";
import {Header, Footer} from "./components";
import {ClanDetailPage} from "./views/clanDetailPage";
import SignUpPage from "./views/signUpPage/SignUpPage";
import LoginPage from "./views/loginPage/LoginPage";
import FreeBoardPage from "./views/board/FreeBoardPage";
import FreeBoardDetailPage from "./views/board/FreeBoardDetailPage";
import FreeBoardWritePage from "./views/board/FreeBoardWritePage";
import WinnerPage from "./views/winnerPage/WinnerPage";
import FreeBoardUpdatePage from "./views/board/FreeBoardUpdatePage";


const MainWrapper = styled.div`
  width: 100%;
`;

function App() {
  return (
    <div className="App">
      <Header/>
      <Wrapper>
        <MainWrapper>
          <Routes>
            <Route exact path="/" element={<HomePage/>}/>
            <Route exact path="/private" element={<PrivatePage/>}/>
            <Route exact path="/private/:userNexonId" element={<UserPage/>}/>
            <Route exact path="/clan" element={<ClanPage/>}/>
            <Route exact path="/league" element={<LeaguePage/>}/>
            <Route path="/record/:userNexonId" element={<RecordPage/>}/>
            <Route path="/clan/:clanId" element={<ClanDetailPage/>}/>
            <Route exact path="/" element={<HomePage/>}/>
            <Route exact path="/private" element={<PrivatePage/>}/>
            <Route exact path="/private/:userNexonId" element={<UserPage/>}/>
            <Route exact path="/clan" element={<ClanPage/>}/>
            <Route exact path="/league" element={<LeaguePage/>}/>
            <Route path="/record/:userNexonId" element={<RecordPage/>}/>
            <Route path="/clan/:clanId" element={<ClanDetailPage/>}/>
            <Route path="/signup" element={<SignUpPage/>}/>
            <Route path="/login" element={<LoginPage/>}/>
            <Route path="/board/free" element={<FreeBoardPage/>}/>
            <Route path="/board/free/:boardId" element={<FreeBoardDetailPage/>}/>
            <Route path="/board/update/:boardId" element={<FreeBoardUpdatePage/>}/>
            <Route path="/board/write" element={<FreeBoardWritePage/>}/>
            <Route path="/winner" element={<WinnerPage/>}/>
          </Routes>
        </MainWrapper>
      </Wrapper>
      <Footer/>
    </div>
  );
}

const Wrapper = styled.div`
  margin: 0 auto;

  min-height: ${(e) => {
  return "100vh";
}};
`;
export default App;
