import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {DESTINATION_DOMAIN_ADDRESS} from "../../utils/constants";


/**
 * 게시물 등록 API
 * */
export const asyncCreateFreeBoardSlice = createAsyncThunk(
  "board/create",
  async ({userId, title, content}) => {
    return await axios
      .post(`${DESTINATION_DOMAIN_ADDRESS}/board`, {
        userId: userId,
        title: title,
        content: content
      })
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err)
      })
  }
);

export const createFreeBoardSlice = createSlice({
    name: "freeBoard/create",
    initialState: {
      content: [],
      status: null,
    },
    reducers: {},
    extraReducers: {
      [asyncCreateFreeBoardSlice.pending]: (state, action) => {
        state.status = "loading";
      },
      [asyncCreateFreeBoardSlice.fulfilled]: (state, action) => {
        state.status = "succeeded";
        state.content = action.payload.content;
      },
      [asyncCreateFreeBoardSlice.rejected]: (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      },
    }
  }
)

export const createFreeBoardReducer = createFreeBoardSlice.reducer
