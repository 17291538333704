import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {DESTINATION_DOMAIN_ADDRESS} from "../../utils/constants";

/**
 * 게시물 등록 API
 * */
export const asyncUpdateFreeBoardSlice = createAsyncThunk(
  "board/update",
  async ({userId, title, content, boardId}) => {
    return await axios
      .patch(`${DESTINATION_DOMAIN_ADDRESS}/board`, {
        content: content,
        title: title,
        boardId: boardId,
        userId: userId
      })
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err)
      })
  }
);

export const updateFreeBoardSlice = createSlice({
    name: "freeBoard/update",
    initialState: {
      content: [],
      status: null,
    },
    reducers: {},
    extraReducers: {
      [asyncUpdateFreeBoardSlice.pending]: (state, action) => {
        state.status = "loading";
      },
      [asyncUpdateFreeBoardSlice.fulfilled]: (state, action) => {
        state.status = "succeeded";
        state.content = action.payload.content;
      },
      [asyncUpdateFreeBoardSlice.rejected]: (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      },
    }
  }
)

export const updateFreeBoardReducer = updateFreeBoardSlice.reducer
