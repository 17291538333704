import {Button} from "react-bootstrap";

const InputClanCode = ({onChangeClanCode, onClickButton, isMobile}) => {
  return (
    <div className="mt-4 mb-2">
      <div style={{fontWeight: "normal", fontSize: isMobile ? "15px" : "18px", marginBottom: "5px",}}>병영수첩</div>
      <input
        style={{
          width: "80%",
          float: "left",
          fontSize: isMobile ? "13px" : "16px"
        }}
        onChange={onChangeClanCode} type="text" className="form-control" id="email" placeholder="병영 수첩 주소에서 숫자를 입력해주세요."
        required/>
      <Button
        onClick={onClickButton}
        style={{
          width: "20%",
          backgroundColor: "#775ee2",
          fontSize: isMobile ? "13px" : "16px"
        }}
      > 확인
      </Button>
      <div style={{marginTop: "12px", fontSize: "14px", color: "gray"}}>예시 - https://barracks.sa.nexon.com/123456/match 여기서 123456 부분 입니다😀</div>
    </div>
  )
}
export default InputClanCode;