import React, {useEffect} from "react";
import {getRemainSeason} from "../../redux/season/remainSeasonSlice";
import {useDispatch, useSelector} from "react-redux";

const POSITION_BUTTON = "battlePlace";

const WinnerPage = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRemainSeason());
  }, [])

  const remainSeason = useSelector((store) => store.remainSeason)

  return (
    <div style={{textAlign: "center", marginTop: "50px"}}>
      <br/>
      <br/>
      <h5> 시즌이 끝나면 </h5>
      <h5>우승 클랜이 명예의 전당에 올라가요 ! 😇</h5>
      <br/>
      <br/>
    </div>
  )
};

export default WinnerPage;
