import {useMediaQuery} from "react-responsive";
import {BREAK_POINT} from "../../utils/constants";
import styled from "@emotion/styled";
import titleImage from "../../assets/logo_1.png";
import {NavLink} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useState} from "react";
import {loginRequest} from "../../redux/login/logInSlice";
import axios from "axios";
import useLocalStorage from "../../hooks/useLocalStorage";

const LoginPage = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAK_POINT})`,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  // 기본정보 저장용 로컬 스토리지
  const [userInfo, setUserInfo] = useLocalStorage("defaultUserInfo", []);

  const handleOnClick = () => {
    (async () => {
      const response = await dispatch(loginRequest({email, password}))
      if (response.payload.data === null) {
        alert(response.payload.message);
      } else {
        const userInfo = response.payload.data;
        const accessToken = userInfo.token
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`

        // 데이터를 지운 후
        localStorage.removeItem("userInfo")

        // 유저 정보 저장
        setUserInfo("userInfo",
          {
            id: userInfo.id,
            email: userInfo.email,
            nickname: userInfo.nickname,
            score: userInfo.score,
            killDeath: userInfo.killDeath,
            clanId: userInfo.clanId,
            clanMarkUrl: userInfo.clanMarkUrl,
            clanNickname: userInfo.clanNickname,
            primaryUseGun: userInfo.primaryUseGun
          }
        )

        alert("로그인성공 😀");
        navigate(`/`);
        window.location.reload();
      }
    })();
  }

  /**
   * 모바일과 데스크탑 같이 사용
   * */
  return (
    <LoginContainer>
      <SignUpInputForm>
        <img
          src={titleImage}
          alt=""
          style={{height: isMobile ? "30px" : "40px", marginBottom: isMobile ? "30px" : "40px"}}
        />
        <div style={{
          fontWeight: "normal", fontSize: isMobile ? "16px" : "18px", marginBottom: "5px"
        }}>이메일
        </div>
        <LoginInput
          onChange={e => setEmail(e.target.value)}
          fontSize={isMobile ? "12px" : "15px"}
          placeholder="이메일을 입력해주세요"/>
        <div style={{
          fontWeight: "normal", marginBottom: "5px", fontSize: isMobile ? "16px" : "18px"
        }}>비밀번호
        </div>
        <LoginInput
          onChange={e => setPassword(e.target.value)}
          type="password"
          placeholder="비밀번호를 입력해주세요"
          fontSize={isMobile ? "12px" : "15px"}
        />
        <LoginButton
          onClick={handleOnClick}
        >로그인</LoginButton>
        <Link to={"/signup"}>
          <div
            style={{
              textAlign: "center",
              marginTop: isMobile ? "20px" : "30px",
              fontSize: isMobile ? "13px" : "15px"
            }}
          >회원가입 😎
          </div>
        </Link>
      </SignUpInputForm>
    </LoginContainer>

  )
}
export default LoginPage;

const LoginInput = styled.input`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 40px;
  margin: 0 0 20px;
  font-size: ${(props) => props.fontSize};
  padding: 5px 39px 5px 11px;
  border: solid 1px #dadada;
  background: #fff;
  border-radius: 10px;
  box-sizing: border-box;
`

const LoginButton = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 49px;
  display: block;
  width: 100%;
  height: 49px;
  margin: 16px 0 7px;
  cursor: pointer;
  text-align: center;
  border-radius: 50px;
  color: #fff;
  border: none;
  background-color: #775ee2;
`

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-inline: 20px;
 `

const SignUpInputForm = styled.div`
  width: 100%;
  max-width: 500px;
  margin-top: 30px;
  padding: 32px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15)
 `