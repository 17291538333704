import {StyledDesktopWrapper} from "./wrapper";
import styled from "@emotion/styled";
import InputEmail from "./InputEmail";
import InputPassword from "./InputPassword";
import InputClanCode from "./InputClanCode";
import {useState} from "react";
import {Button} from "react-bootstrap";
import {validEmail} from "../redux/signup/validEmailSlice";
import {useDispatch} from "react-redux";
import {validClanCode} from "../redux/signup/validClanCodeSlice";
import {signUpRequest} from "../redux/signup/signUpSlice";
import {useNavigate} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import {BREAK_POINT} from "../utils/constants";

const SignUp = () => {

  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAK_POINT})`,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 회원가입 정보
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [clanCode, setClanCode] = useState("");

  // 오류 메세지
  const [emailMessage, setEmailMessage] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [rePasswordMessage, setRePasswordMessage] = useState("");
  const [clanCodeMessage, setClanCodeMessage] = useState("");

  // 유효성 검사
  const [isEmail, setIsEmail] = useState(false)
  const [isPassword, setIsPassword] = useState(false)
  const [isRePassword, setIsRePassword] = useState(false)

  // 중복 체크 여부
  const [validEmailCheck, setValidEmailCheck] = useState(false)
  const [validClanCheck, setValidClanCheck] = useState(false)

  // 패스워드 유효성 검사
  const onChangePassword = (e) => {
    setPassword(e.target.value)
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/
    const passwordCurrent = e.target.value

    if (!passwordRegex.test(passwordCurrent)) {
      setPasswordMessage('숫자 + 영문자 + 특수문자 조합으로 8자리 이상 입력해주세요 😭')
      setIsPassword(false)
    } else {
      setPasswordMessage('안전한 비밀번호에요 😀')
      setIsPassword(true)
    }
  }

  // 패스워드 유효성 검사
  const onChangeRePassword = (e) => {
    setRePassword(e.target.value)
    const inputPassword = e.target.value;
    if (password !== inputPassword) {
      setRePasswordMessage('비밀번호가 일치하지 않아요 😭');
      setIsRePassword(false)
    } else {
      setRePasswordMessage('비밀번호가 일치해요 😀')
      setIsRePassword(true)
    }
  }

  // 이메일 유효성 검사
  const onChangeEmail = (e) => {
    setEmail(e.target.value)
    const emailRegex = /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
    const emailCurrent = e.target.value

    if (!emailRegex.test(emailCurrent)) {
      setEmailMessage('이메일 형식이 잘못됐어요 😭')
      setIsEmail(false)
    } else {
      setEmailMessage('올바른 이메일 형식이에요 😀')
      setIsEmail(true)
    }
  }

  const onChangeClanCode = (e) => {
    setClanCode(e.target.value)
  }

  // 모든 값들을 정상적으로 체크했는지 확인
  function isAllValid() {
    return isEmail && isPassword && isRePassword && validEmailCheck && validClanCheck
  }


  const handleOnClick = () => {
    (async () => {
      const isAvailable = await dispatch(signUpRequest({email, password, rePassword, clanCode}))
      if (isAvailable === undefined) {
        alert("회원가입에 실패했에요 다시 작성해주세요 😭");
      } else {
        alert("회원가입 성공! 😀")
        navigate(`/`);
      }
    })();
  }

  const validCheckEmail = () => {
    if (isEmail === true) {
      (async () => {
        const response = await dispatch(validEmail(email))

        if (response.payload.data !== null) {
          setEmailMessage("등록 가능한 이메일이에요 😀")
          setValidEmailCheck(true)
        } else {
          setEmailMessage("이미 등록된 이메일이에요 😭")
          setValidEmailCheck(false)
        }
      })();
    }
  }

  const validCheckClanCode = () => {
    if (clanCode !== "") {
      (async () => {
        const isAvailable = await dispatch(validClanCode(clanCode))
        if (isAvailable.payload === undefined) {
          setClanCodeMessage("존재하지 않는 병영수첩주소 에요 😭")
          setValidClanCheck(false)
        } else {
          if (isAvailable.payload.data === true) {
            setClanCodeMessage("등록된 병영수첩주소 입니다 😀")
            setValidClanCheck(true)
          }
        }
      })();
    }
  };

  return (
    <StyledDesktopWrapper>
      <SignUpInputForm>
        <div className="mb-3"></div>
        <h2 className="mb-3">회원가입</h2>
        <InputEmail
          onChangeEmail={onChangeEmail}
          onClickButton={validCheckEmail}
          isMobile={isMobile}
        />
        <span style={{
          color: (isEmail && validEmailCheck) ? '#775ee2' : "red" ,
          fontSize: isMobile ? "12px" : "15px"
        }}>{emailMessage}</span>
        <InputPassword
          class="mb-2"
          titleName={"비밀번호"}
          placeHoldName={"비밀번호를 입력해주세요"}
          onChangePassword={onChangePassword}
          isMobile={isMobile}
        />
        <span style={{
          color: isPassword ? '#775ee2' : "red",
          fontSize: isMobile ? "12px" : "15px"
        }}>{passwordMessage}</span>
        <InputPassword
          titleName={"비밀번호 재입력"}
          placeHoldName={"비밀번호를 다시 한번 입력해주세요"}
          onChangePassword={onChangeRePassword}
          isMobile={isMobile}
        />
        <span style={{
          color: isRePassword ? '#775ee2' : "red",
          fontSize: isMobile ? "12px" : "15px"
        }}>{rePasswordMessage}</span>
        <InputClanCode
          onChangeClanCode={onChangeClanCode}
          onClickButton={validCheckClanCode}
          isMobile={isMobile}
        />
        <span style={{
          color: validClanCheck ? '#775ee2' : "red",
          fontSize: isMobile ? "12px" : "15px"
        }}>{clanCodeMessage}</span>
        <Button
          style={{
            marginTop: isMobile ? "20px" : "50px",
            backgroundColor: "#775ee2",
            width: "100%",
            height: isMobile ? "50px" : "75px",
            fontSize: isMobile ? "14px" : "18px",
            borderRadius: "50px"
          }}
          disabled={!isAllValid()}
          onClick={handleOnClick}
        > 회원가입 😎</Button>
      </SignUpInputForm>
    </StyledDesktopWrapper>
  )
}

const SignUpInputForm = styled.div`
        width: 100%;
        max-width: 680px;
        margin-top: 20px;
        padding: 32px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15)
        `

export default SignUp;
