import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {DESTINATION_DOMAIN_ADDRESS} from "../../utils/constants";


/**
 * 댓글등록 API
 * */
export const asyncCreateCommentSlice = createAsyncThunk(
  "comment/create",
  async ({userId, boardId, content}) => {
    return await axios
      .post(`${DESTINATION_DOMAIN_ADDRESS}/board/comment`, {
        boardId: boardId,
        userId: userId,
        content: content
      })
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err)
      })
  }
);

export const createCommentSlice = createSlice({
    name: "comment/create",
    initialState: {
      content: [],
      status: null,
    },
    reducers: {},
    extraReducers: {
      [asyncCreateCommentSlice.pending]: (state, action) => {
        state.status = "loading";
      },
      [asyncCreateCommentSlice.fulfilled]: (state, action) => {
        state.status = "succeeded";
        state.content = action.payload.content;
      },
      [asyncCreateCommentSlice.rejected]: (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      },
    }
  }
)

export const createCommentReducer = createCommentSlice.reducer
