import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {DESTINATION_DOMAIN_ADDRESS} from "../../utils/constants";

export const validClanCode = createAsyncThunk(
  "signUp/clan",
  async (clanId) => {
    return await axios
      .get(`${DESTINATION_DOMAIN_ADDRESS}/user/nexonId?nexonId=${clanId}`)
      .then((res) => res.data)
      .catch((err) => err.data)
  }
);

export const validClanCodeSlice = createSlice({
    name: "validClanCode",
    initialState: {
      content: [],
      status: null,
    },
    reducers: {},
    extraReducers: {
      [validClanCode.pending]: (state, action) => {
        state.status = "loading";
      },
      [validClanCode.fulfilled]: (state, action) => {
        state.status = "succeeded";
        state.content = action.payload;
      },
      [validClanCode.rejected]: (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      },
    }
  }
);

export const validClanCodeReducer = validClanCodeSlice.reducer;