import React, { useEffect } from "react";

const AdSenseComponent = () => {
    useEffect(() => {
        // Google AdSense 스크립트가 이미 로드되어 있는지 확인하고 광고 초기화
        const loadAds = () => {
            if (window.adsbygoogle && window.adsbygoogle.length > 0) {
                window.adsbygoogle.push({});
            } else {
                // 스크립트가 로드되지 않았다면 추가로 로드
                const script = document.createElement("script");
                script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
                script.async = true;
                script.onload = () => {
                    window.adsbygoogle.push({});
                };
                document.body.appendChild(script);
            }
        };

        loadAds();
    }, []); // 컴포넌트가 마운트될 때 한 번 실행

    return (
        <div>
            <ins
                className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-1751742582279259" // 광고 ID
                data-ad-slot="3013463188"               // 광고 슬롯 ID
                data-ad-format="auto"
                data-full-width-responsive="true"
            ></ins>
        </div>
    );
};

export default AdSenseComponent;
