import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {DESTINATION_DOMAIN_ADDRESS} from "../../utils/constants";


/**
 * 게시물 등록 API
 * */
export const asyncFeedBackFreeBoardSlice = createAsyncThunk(
  "board/create",
  async ({feedBackId, feedBackType, targetType, actionType, userId}) => {
    return await axios
      .patch(`${DESTINATION_DOMAIN_ADDRESS}/board/feedback`, {
        feedBackId: feedBackId,
        feedBackType: feedBackType,
        targetType: targetType,
        actionType: actionType,
        userId: userId
      })
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err)
      })
  }
);

export const feedBackFreeBoardSlice = createSlice({
    name: "freeBoard/create",
    initialState: {
      content: [],
      status: null,
    },
    reducers: {},
    extraReducers: {
      [asyncFeedBackFreeBoardSlice.pending]: (state, action) => {
        state.status = "loading";
      },
      [asyncFeedBackFreeBoardSlice.fulfilled]: (state, action) => {
        state.status = "succeeded";
        state.content = action.payload.content;
      },
      [asyncFeedBackFreeBoardSlice.rejected]: (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      },
    }
  }
)

export const feedBackFreeBoardReducer = feedBackFreeBoardSlice.reducer
