import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {DESTINATION_DOMAIN_ADDRESS} from "../../utils/constants";
import {useDispatch} from "react-redux";


export const signUpRequest = createAsyncThunk(
  "signUp/request",
  async ({email, password, rePassword, clanCode}) => {
    return await axios
      .post(`${DESTINATION_DOMAIN_ADDRESS}/user/signup`, {
        email: email,
        password: password,
        rePassword: rePassword,
        userNexonId: clanCode
      })
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
      })
  }
);


export const signUpSlice = createSlice({
    name: "signUpRequest",
    initialState: {
      content: [],
      status: null,
    },
    reducers: {},
    extraReducers: {
      [signUpRequest.pending]: (state, action) => {
        state.status = "loading";
      },
      [signUpRequest.fulfilled]: (state, action) => {
        state.status = "succeeded";
        state.content = action.payload;
      },
      [signUpRequest.rejected]: (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      },
    }
  }
);

export const signUpSliceReducer = signUpSlice.reducer;