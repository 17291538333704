import {useMediaQuery} from "react-responsive";
import {BREAK_POINT} from "../../utils/constants";
import FreeDesktop from "./FreeDesktop";

const FreeBoardPage = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAK_POINT})`,
  });


  return isMobile ? (
    <div style={{marginTop: "50px", textAlign: "center"}}>
      <h5>아직은 데스크탑에서만 사용 할 수 있어요 😭</h5>
      <h5>잠시만 기다려주세요</h5>
    </div>

  ) : (
    <FreeDesktop
    />
  );
};

export default FreeBoardPage;
