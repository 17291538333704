import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {DESTINATION_DOMAIN_ADDRESS} from "../../utils/constants";

export const getFreeBoardDetailSlice = createAsyncThunk(
  "board/free/detail",
  async ({ boardId }) => {
    return await axios
      .get(`${DESTINATION_DOMAIN_ADDRESS}/board/${boardId}`)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const freeBoardDetailSlice = createSlice({
  name: "clanRankingList",
  initialState: {
    content: [],
    status: null,
  },
  reducers: {},
  extraReducers: {
    [getFreeBoardDetailSlice.pending]: (state, action) => {
      state.status = "loading";
    },
    [getFreeBoardDetailSlice.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.content = action.payload;
    },
    [getFreeBoardDetailSlice.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const freeBoardDetailReducer = freeBoardDetailSlice.reducer;
