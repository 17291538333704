import {Chip, Divider, Grid, IconButton} from "@mui/material";
import sty from "@emotion/styled";
import {COMMON_LAYOUT_PC_HORIZONTAL_MAX} from "../../utils/constants";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {useEffect, useState} from "react";
import {getFreeBoardDetailSlice} from "../../redux/board/freeBoardDetailSlice";
import FreeBoardComment from "../../components/FreeBoardComment";
import Typography from "@mui/material/Typography";
import Badge from "react-bootstrap/Badge";
import {StyledButtonWrapper} from "../../components/wrapper";
import reactParser from 'html-react-parser'
import {asyncCreateCommentSlice} from "../../redux/board/createCommentBoardSlice";
import useLocalStorage from "../../hooks/useLocalStorage";
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import {asyncUpdateViewBoardSlice} from "../../redux/board/updateViewBoardSlice";
import {Cookies} from "react-cookie";
import {asyncFeedBackFreeBoardSlice} from "../../redux/board/feedBackFreeBoardSlice";
import {asyncDeleteFreeBoardSlice} from "../../redux/board/deleteFreeBoardSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const FreeBoardDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const freeBoard = useSelector((store) => store.freeBoardDetailRequest);
  const {boardId} = useParams();
  const freeBoardContent = freeBoard.content;
  const [comment, setComment] = useState("")
  const [userInfo] = useLocalStorage("userInfo", []);
  const cookies = new Cookies()

  useEffect(() => {
    updateViewCheck()
    dispatch(getFreeBoardDetailSlice({boardId}))
  }, []);

  // 좋아요 업데이트
  const updateGood = () => {
    if (userInfo['id'] === undefined) {
      alert("로그인 하고 누를 수 있어요 😭")
      return
    }

    if (cookies.get("GOOD" + boardId) === undefined) {
      if (cookies.get("BAD" + boardId) !== undefined) {
        alert("좋아요나 싫어요는 한번만 누를 수 있어요 😭")
        return
      }

      dispatch(asyncFeedBackFreeBoardSlice({
        feedBackId: freeBoardContent.id,
        feedBackType: "GOOD",
        targetType: "BOARD",
        actionType: "INSERT",
        userId: userInfo['id']
      }));
      cookies.set("GOOD" + boardId, "GOOD" + boardId, {
        path: "/", expire: new Date().setMonth(6)
      });

      window.location.href = `/board/free/${freeBoardContent.id}`;
    } else {
      alert("좋아요나 싫어요는 한번만 누를 수 있어요 😭")
    }
  }


  // 싫어요 업데이트
  const updateBad = () => {
    if (userInfo['id'] === undefined) {
      alert("로그인 하고 누를 수 있어요 😭")
      return
    }

    // 쿠키에 값이 들어 있지 않으면 조회수 업데이트
    if (cookies.get("BAD" + boardId) === undefined) {
      if (cookies.get("GOOD" + boardId) !== undefined) {
        alert("좋아요나 싫어요는 한번만 누를 수 있어요 😭")
        return
      }
      dispatch(asyncFeedBackFreeBoardSlice({
        feedBackId: freeBoardContent.id,
        feedBackType: "BAD",
        targetType: "BOARD",
        actionType: "INSERT",
        userId: userInfo['id']
      }))
      cookies.set("BAD" + boardId, "BAD" + boardId, {
        path: "/", expire: new Date().setMonth(6)
      })

      window.location.href = `/board/free/${freeBoardContent.id}`
    } else {
      alert("좋아요나 싫어요는 한번만 누를 수 있어요 😭")
    }
  }

  // 조회수를 업데이트 할지 체크한다
  const updateViewCheck = () => {
    // 쿠키에 값이 들어 있지 않으면 조회수 업데이트
    if (cookies.get(boardId) === undefined) {
      dispatch(asyncUpdateViewBoardSlice({boardId}))
      cookies.set(boardId, boardId, {
        path: "/", expire: new Date().setMonth(1)
      })
    }
  }

  const deleteContent = () => {
    (async () => {
      const isAvailable = await dispatch(asyncDeleteFreeBoardSlice({
        boardId: boardId, userId: userInfo['id']
      }))
      if (isAvailable === undefined) {
        alert("글삭제에 실패했어요 😭");
      } else {
        navigate(`/board/free`);
      }
    })();
  }

  const onHandleComment = () => {
    if (userInfo["id"] === undefined) {
      alert("로그인 후 입력이 가능해요 😭");
      return
    }

    if (comment.length === 0) {
      alert("댓글을 입력해주세요 😭");
      return
    }

    (async () => {
      const isAvailable = await dispatch(asyncCreateCommentSlice({
        boardId: boardId, userId: userInfo['id'], content: comment
      }))
      if (isAvailable === undefined) {
        alert("글작성에 실패했어요 😭");
      } else {
        window.location.href = `/board/free/${boardId}`
      }
    })();
  }

  const goEdit =() => {
    navigate(`/board/update/${boardId}`)
  }

  return (<Grid container spacing={2}>
    <Grid item xs={12}>
      <StyledDesktopWrapper>
        <BoardForm>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <BoardTitle>{freeBoardContent.title}</BoardTitle>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{
                  fontSize: 20, display: "flex", alignItems: "center"
                }} gutterBottom>
                  {freeBoardContent.nickName}
                  <Badge style={{fontSize: 11, marginLeft: "7px"}}
                         bg="secondary">{freeBoardContent.clanNickName}</Badge>
                </Typography>
                <div style={{fontSize: "10px", color: "gray", marginBottom: "10px"}}>
                  <span style={{fontSize: "16px"}}>{freeBoardContent.createdAt}</span>
                  <span style={{marginLeft: "20px", fontSize: "16px"}}>👀 : {freeBoardContent.view}</span>
                  <span>
                      {userInfo['id'] === freeBoardContent.userId ?
                        <IconButton
                          onClick={deleteContent}
                          size="large">
                          <DeleteIcon fontSize="small"/>
                        </IconButton> : <div></div>}
                      </span>
                  <span>
                      {userInfo['id'] === freeBoardContent.userId ?
                        <IconButton
                          onClick={goEdit}
                          size="large">
                          <EditIcon fontSize="small"/>
                        </IconButton> : <div></div>}
                    </span>
                </div>
              </Grid>
              <hr style={{width: "100%", color: "gray"}}/>
            </Grid>
            <div style={{marginBottom: "60px", marginTop: "20px"}}></div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BoardContent>
                  {(freeBoardContent.content !== undefined) ? reactParser(freeBoardContent.content) : ""}
                </BoardContent>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>

                <IconButton
                  onClick={updateGood}
                  style={{
                    marginRight: "20px"
                  }} aria-label="down" size="large">
                  <ThumbUpOffAltIcon fontSize="large"/> {freeBoardContent.good}
                </IconButton>

                <IconButton
                  onClick={updateBad}
                  aria-label="up" size="large">
                  <ThumbDownOffAltIcon fontSize="large"/> {freeBoardContent.bad}
                </IconButton>

              </Grid>
              <Grid item xs={10}></Grid>
              <div style={{width: "100%"}}>
                <Divider style={{width: "100%"}}>
                  <Chip label="댓글 💬"/>
                </Divider>
              </div>
              <Grid item xs={12}>
                {(freeBoardContent.comment || []).map((comment, index) => (<div>
                  <FreeBoardComment
                    comment={comment}
                  />
                </div>))}
              </Grid>
              <Grid item xs={12} style={{minHeight: "100px", width: "100px"}}>
                <Grid style={{
                  marginBottom: "40px", marginTop: "5px"
                }} container spacing={2}>
                  <Grid item xs={9}>
                    <input
                      onChange={event => setComment(event.target.value)}
                      type="text" className="form-control"
                      placeholder="댓글을 입력해주세요"
                      required/>
                  </Grid>
                  <Grid item={3}>
                    <StyledButtonWrapper
                      onClick={onHandleComment}
                      justifyContent={"center"}
                      height={"40px"}
                      style={{
                        width: "100px",
                        alignItems: "center",
                        background: "#775ee1",
                        color: "white",
                        fontSize: "15px",
                        borderRadius: "20px",
                        cursor: "pointer",
                      }}
                    >등록
                    </StyledButtonWrapper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <ReplyContent
                  style={{
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    navigate(`/board/free`);
                  }}>목록</ReplyContent>
              </Grid>
            </Grid>
          </div>
        </BoardForm>
      </StyledDesktopWrapper>
    </Grid>
  </Grid>);
};
export default FreeBoardDetailPage;

const StyledDesktopWrapper = sty.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-inline: 20px;
  max-width: ${COMMON_LAYOUT_PC_HORIZONTAL_MAX};
`;

const BoardForm = sty.div`
        width: 100%;
        max-width: 1100px;
        padding: 60px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.50)
        `
const BoardContent = sty.div`
    min-Height: 350px;
`

const ReplyContent = sty.div`
    margin-top: 20px;
    min-Height: 150px;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
`

const BoardTitle = sty.div`
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 30px;
`;

