import Typography from "@mui/material/Typography";
import Badge from "react-bootstrap/Badge";
import {Grid, IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {useState} from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import {StyledButtonWrapper} from "./wrapper";
import {asyncCreateReplySlice} from "../redux/board/createReplyBoardSlice";
import {useDispatch} from "react-redux";
import {asyncUpdateReactionBoardSlice} from "../redux/board/updateReactionBoardSlice";
import {asyncDeleteReactionBoardSlice} from "../redux/board/deleteReactionBoardSlice";

const FreeBoardReply = (
  {reply}
) => {

  // 댓글 수정하기
  const [showReplyEditor, setShowReplyEditor] = useState(false)
  const [replyEditContent, setReplyEditContent] = useState("")
  const [userInfo] = useLocalStorage("userInfo", []);
  const dispatch = useDispatch();

  console.log("====== REPLY")
  console.log(reply)


  const onHandleUpdateComment = () => {
    (async () => {
      const isAvailable = await dispatch(asyncUpdateReactionBoardSlice({
        content: replyEditContent,
        type: "REPLY",
        boardId: reply.boardId,
        userId: userInfo['id'],
        reactionId: reply.id,
      }))
      if (isAvailable === undefined) {
        alert("수정에 실패했어요 😭");
      } else {
        window.location.href = `/board/free/${reply.boardId}`
      }
    })();
  };

  const onHandleDelete = () => {
    (async () => {
      const isAvailable = await dispatch(asyncDeleteReactionBoardSlice({
        content: replyEditContent,
        type: "REPLY",
        userId: userInfo['id'],
        reactionId: reply.id,
      }))
      if (isAvailable === undefined) {
        alert("삭제에 실패했어요 😭");
      } else {
        window.location.href = `/board/free/${reply.boardId}`
      }
    })();
  }

  const onShowEditModal = () => {
    showReplyEditor === true ? setShowReplyEditor(false) : setShowReplyEditor(true)
  }

  return (
    <div style={{paddingLeft: "15px"}}>
      {
        reply.deleted === false ?
          <Grid style={{backgroundColor: "#FFF5EE"}} container>
            <Grid item xs={10}>
              <Typography sx={{
                fontSize: 18, display: "flex", alignItems: "center"
              }} gutterBottom>
                {reply.userNickName}
                <Badge style={{fontSize: 11, marginLeft: "7px"}} bg="secondary">{reply.clanNickName}</Badge>
              </Typography>

              <div style={{fontSize: "10px", color: "gray", marginBottom: "10px"}}>
                <span style={{fontSize: "12px"}}>{reply.createdAt}</span>
              </div>
            </Grid>

            <Grid item xs={2}>
              {
                userInfo["id"] === reply.userId ?
                  <IconButton
                    onClick={onHandleDelete}
                    size="large">
                    <DeleteIcon fontSize="small"/>
                  </IconButton>
                  : <div></div>
              }
              {
                userInfo["id"] === reply.userId ?
                  <IconButton
                    onClick={onShowEditModal}
                    size="large">
                    <EditIcon fontSize="small"/>
                  </IconButton>
                  : <div></div>
              }
            </Grid>
            <Typography variant="body1" color="text.secondary">
              {reply.content}
            </Typography>
          </Grid>
          : <div style={{color: "gray", minHeight: "24px"}}>삭제된 대댓글이에요 ❌</div>
      }
      {
        (showReplyEditor === true) ?
          <Grid style={{
            marginBottom: "40px",
            marginTop: "5px"
          }} container spacing={2}>
            <Grid item xs={9}>
              <input
                onChange={event => setReplyEditContent(event.target.value)}
                type="text" className="form-control"
                placeholder="수정할 내용을 입력해주세요"
                required/>
            </Grid>
            <Grid item={3}>
              <StyledButtonWrapper
                onClick={onHandleUpdateComment}
                justifyContent={"center"}
                height={"40px"}
                style={{
                  width: "100px",
                  alignItems: "center",
                  background: "#775ee1",
                  color: "white",
                  fontSize: "15px",
                  borderRadius: "20px",
                  cursor: "pointer",
                }}
              >수정
              </StyledButtonWrapper>
            </Grid>
          </Grid>
          : ""
      }
    </div>
  )
}
export default FreeBoardReply;