import {
  combineReducers,
  configureStore,
  createAction,
} from "@reduxjs/toolkit";
import {bannerReducer} from "./banner";
import {userSearchReducer} from "./user";
import {battleReducer} from "./battle";
import {rankingReducer} from "./ranking";
import {mapReducer} from "./map";
import {clanRankingReducer} from "./clan";
import {privateRankingReducer} from "./private";
import {
  clanRecordsReducer,
  recentRecordsReducer,
  userRecordsReducer,
} from "./record";
import {userInfoReducer} from "./user/userInfoSlice";
import {matchDetailSliceReducer} from "./record/matchDetailSlice";
import {clanInfoReducer} from "./clan/clanInfoSlice";
import {includedReducer} from "./clan/includedClansSlice";
import {remainSeasonReducer} from "./season/remainSeasonSlice";
import {validEmailReducer} from "./signup/validEmailSlice";
import {validClanCodeReducer} from "./signup/validClanCodeSlice";
import {signUpSliceReducer} from "./signup/signUpSlice";
import {loginSliceReducer} from "./login/logInSlice";
import {freeBoardReducer, getFreeBoardReducer} from "./board/freeBoardSlice";
import {freeBoardDetailReducer} from "./board/freeBoardDetailSlice";
import {updateFreeBoardReducer} from "./board/updateFreeBoardSlice";
import {deleteFreeBoardReducer} from "./board/deleteFreeBoardSlice";
import {createFreeBoardReducer} from "./board/createFreeBoardSlice";
import {feedBackFreeBoardReducer} from "./board/feedBackFreeBoardSlice";
import {createCommentReducer} from "./board/createCommentBoardSlice";
import {createReplyReducer} from "./board/createReplyBoardSlice";
import {updateReactionBoardReducer} from "./board/updateReactionBoardSlice";
import {deleteReactionBoardReducer} from "./board/deleteReactionBoardSlice";
import {updateViewBoardReducer} from "./board/updateViewBoardSlice";

export function resetStore() {
  return {
    type: "reset",
    payload: undefined,
  };
}

const combinedReducer = combineReducers({
  banner: bannerReducer,
  userSearch: userSearchReducer,
  userInfo: userInfoReducer,
  includedClans: includedReducer,
  remainSeason: remainSeasonReducer,
  clanInfo: clanInfoReducer,
  battle: battleReducer,
  ranking: rankingReducer,
  map: mapReducer,
  clanRankingList: clanRankingReducer,
  privateRankingList: privateRankingReducer,
  userRecords: userRecordsReducer,
  clanRecords: clanRecordsReducer,
  matchDetail: matchDetailSliceReducer,
  recentRecords: recentRecordsReducer,
  validEmail: validEmailReducer,
  validClan: validClanCodeReducer,
  signUpRequest: signUpSliceReducer,
  loginRequest: loginSliceReducer,
  freeBoardRequest: freeBoardReducer,
  freeBoardDetailRequest: freeBoardDetailReducer,
  updateFreeBoardRequest: updateFreeBoardReducer,
  deleteFreeBoardRequest: deleteFreeBoardReducer,
  createFreeBoardRequest: createFreeBoardReducer,
  feedBackFreeBoardRequest: feedBackFreeBoardReducer,
  createCommentRequest: createCommentReducer,
  createFeedBackRequest: createFreeBoardReducer,
  createReplyRequest: createReplyReducer,
  updateReactionRequest: updateReactionBoardReducer,
  deleteReactionRequest: deleteReactionBoardReducer,
  updateViewRequest: updateViewBoardReducer
});
const rootReducer = (state, action) => {
  if (action.type === "reset") {
    state = {};
  }

  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});
