import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {DESTINATION_DOMAIN_ADDRESS} from "../../utils/constants";


/**
 * 대댓글등록 API
 * */
export const asyncCreateReplySlice = createAsyncThunk(
  "comment/create",
  async ({userId, boardId, content, commentId}) => {
    return await axios
      .post(`${DESTINATION_DOMAIN_ADDRESS}/board/reply`, {
        boardId: boardId,
        userId: userId,
        content: content,
        commentId: commentId
      })
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err)
      })
  }
);

export const createReplySlice = createSlice({
    name: "comment/create",
    initialState: {
      content: [],
      status: null,
    },
    reducers: {},
    extraReducers: {
      [asyncCreateReplySlice.pending]: (state, action) => {
        state.status = "loading";
      },
      [asyncCreateReplySlice.fulfilled]: (state, action) => {
        state.status = "succeeded";
        state.content = action.payload.content;
      },
      [asyncCreateReplySlice.rejected]: (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      },
    }
  }
)

export const createReplyReducer = createReplySlice.reducer
