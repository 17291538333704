const DESTINATION_DOMAIN_ADDRESS = "https://api.friendbattle.net";
const BREAK_POINT = "980px";
const PLACE_BUTTON = "battlePlace";
const GUN_BUTTON = "battleGun";
const ROUND_BUTTON = "battleRound";
const COMMON_LAYOUT_HORIZONTAL_PADDING = "128px";
const COMMON_LAYOUT_PC_HORIZONTAL_MAX = "1632px";

export {
  DESTINATION_DOMAIN_ADDRESS,
  BREAK_POINT,
  PLACE_BUTTON,
  GUN_BUTTON,
  ROUND_BUTTON,
  COMMON_LAYOUT_HORIZONTAL_PADDING,
  COMMON_LAYOUT_PC_HORIZONTAL_MAX,
};
