import styled from "@emotion/styled";

const TablePageTitleWrapper = styled.div`
  background: white;
  padding: 56px;
  width: 100%;
  height: 214px;
`;

export default TablePageTitleWrapper;
