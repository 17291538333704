import Typography from "@mui/material/Typography";
import Badge from "react-bootstrap/Badge";
import FreeBoardReply from "./FreeBoardReply";
import reactParser from 'html-react-parser'
import {Grid, IconButton, Modal} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import useLocalStorage from "../hooks/useLocalStorage";
import {useState} from "react";
import {StyledButtonWrapper} from "./wrapper";
import {asyncCreateReplySlice} from "../redux/board/createReplyBoardSlice";
import {useDispatch} from "react-redux";
import {asyncDeleteReactionBoardSlice} from "../redux/board/deleteReactionBoardSlice";
import EditIcon from '@mui/icons-material/Edit';
import {asyncUpdateReactionBoardSlice} from "../redux/board/updateReactionBoardSlice";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import * as React from 'react';
import CustomModal from "./CustomModal";


const FreeBoardComment = (
  {comment}
) => {
  const [userInfo] = useLocalStorage("userInfo", []);

  // 대댓글 달기
  const [showEditor, setShowEditor] = useState(false)
  const [replyContent, setReplyContent] = useState("")


  // 댓글 수정하기
  const [showCommentEditor, setShowCommentEditor] = useState(false)
  const [commentContent, setCommerceContent] = useState("")

  const dispatch = useDispatch()

  const onHandleReply = () => {
    if (replyContent.length === 0) {
      alert("대댓글을 입력해주세요 😭");
      return
    }

    (async () => {
      const isAvailable = await dispatch(asyncCreateReplySlice({
        boardId: comment.boardId,
        commentId: comment.id,
        userId: userInfo['id'],
        content: replyContent
      }))
      if (isAvailable === undefined) {
        alert("글작성에 실패했어요 😭");
      } else {
        window.location.href = `/board/free/${comment.boardId}`
      }
    })();
  }

  const clickModal = () => {
    showEditor === true ? setShowEditor(false) : setShowEditor(true)
  }

  const clickCommentEditModal = () => {
    showCommentEditor === true ? setShowCommentEditor(false) : setShowCommentEditor(true)
  }

  const onHandleUpdateComment = () => {
    (async () => {
      const isAvailable = await dispatch(asyncUpdateReactionBoardSlice({
        content: commentContent,
        type: "COMMENT",
        boardId: comment.boardId,
        userId: userInfo['id'],
        reactionId: comment.id,
      }))
      if (isAvailable === undefined) {
        alert("수정에 실패했어요 😭");
      } else {
        window.location.href = `/board/free/${comment.boardId}`
      }
    })();
  }

  const onHandleDeleteComment = () => {
    (async () => {
      const isAvailable = await dispatch(asyncDeleteReactionBoardSlice({
        type: "COMMENT",
        reactionId: comment.id,
        userId: userInfo['id'],
      }))
      if (isAvailable === undefined) {
        alert("삭제에 실패했어요 😭");
      } else {
        window.location.href = `/board/free/${comment.boardId}`
      }
    })();
  }


  return (
    <div style={{minHeight: "25px"}}>
      {
        comment.deleted === false ?
          <div>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Typography sx={{
                  fontSize: 18, display: "flex", alignItems: "center"
                }} gutterBottom>
                  {comment.userNickName}
                  <Badge style={{fontSize: 11, marginLeft: "7px"}} bg="secondary">{comment.clanNickName}</Badge>
                </Typography>
                <div style={{fontSize: "10px", color: "gray", marginBottom: "10px"}}>
                  <span style={{fontSize: "12px"}}>{comment.createdAt}</span>
                </div>
              </Grid>
              <Grid item xs={2}>
                {
                  userInfo['id'] !== undefined ?
                    <IconButton aria-label="delete" size="large">
                      <QuestionAnswerIcon
                        onClick={clickModal}
                        fontSize="small"/>
                    </IconButton>
                    : <div></div>
                }
                {
                  userInfo['id'] === comment.userId ?
                    <IconButton
                      onClick={onHandleDeleteComment}
                      size="large">
                      <DeleteIcon fontSize="small"/>
                    </IconButton>
                    : <div></div>
                }
                {
                  userInfo['id'] === comment.userId ?
                    <IconButton
                      onClick={clickCommentEditModal}
                      size="large">
                      <EditIcon fontSize="small"/>
                    </IconButton>
                    : <div></div>
                }
              </Grid>
            </Grid>
            <Typography variant="body1" color="text.secondary">
              {(comment.content !== undefined) ? reactParser(comment.content) : ""}
            </Typography>
          </div>

          : <div style={{color: "gray", minHeight: "24px"}}>삭제된 댓글이에요 ❌</div>
      }

      <hr style={{width: "100%", color: "gray"}}/>
      {
        (showEditor === true) ?
          <Grid style={{
            marginBottom: "40px",
            marginTop: "5px"
          }} container spacing={2}>
            <Grid item xs={9}>
              <input
                onChange={event => setReplyContent(event.target.value)}
                type="text" className="form-control"
                placeholder="대댓글을 입력해주세요"
                required/>
            </Grid>
            <Grid item={3}>
              <StyledButtonWrapper
                onClick={onHandleReply}
                justifyContent={"center"}
                height={"40px"}
                style={{
                  width: "100px",
                  alignItems: "center",
                  background: "#775ee1",
                  color: "white",
                  fontSize: "15px",
                  borderRadius: "20px",
                  cursor: "pointer",
                }}
              >등록
              </StyledButtonWrapper>
            </Grid>
          </Grid>
          : ""
      }
      {
        (showCommentEditor === true) ?
          <Grid style={{
            marginBottom: "40px",
            marginTop: "5px"
          }} container spacing={2}>
            <Grid item xs={9}>
              <input
                onChange={event => setCommerceContent(event.target.value)}
                type="text" className="form-control"
                placeholder="수정할 내용을 입력해주세요"
                required/>
            </Grid>
            <Grid item={3}>
              <StyledButtonWrapper
                onClick={onHandleUpdateComment}
                justifyContent={"center"}
                height={"40px"}
                style={{
                  width: "100px",
                  alignItems: "center",
                  background: "#775ee1",
                  color: "white",
                  fontSize: "15px",
                  borderRadius: "20px",
                  cursor: "pointer",
                }}
              >수정
              </StyledButtonWrapper>
            </Grid>
          </Grid>
          : ""
      }

      {(comment.reply || []).map((replyData) => (
        <div>
          <FreeBoardReply
            reply={replyData}
          />
          <hr style={{width: "100%", color: "gray"}}/>
        </div>
      ))}
    </div>
  )
}
export default FreeBoardComment;