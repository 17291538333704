import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {DESTINATION_DOMAIN_ADDRESS} from "../../utils/constants";

/**
 * 게시물 등록 API
 * */
export const asyncUpdateReactionBoardSlice = createAsyncThunk(
  "board/update",
  async ({userId, reactionId, content, boardId, type}) => {
    return await axios
      .patch(`${DESTINATION_DOMAIN_ADDRESS}/board/reaction`, {
        content: content,
        type: type,
        boardId: boardId,
        userId: userId,
        reactionId: reactionId
      })
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err)
      })
  }
);

export const updateReactionBoardSlice = createSlice({
    name: "freeBoard/update",
    initialState: {
      content: [],
      status: null,
    },
    reducers: {},
    extraReducers: {
      [asyncUpdateReactionBoardSlice.pending]: (state, action) => {
        state.status = "loading";
      },
      [asyncUpdateReactionBoardSlice.fulfilled]: (state, action) => {
        state.status = "succeeded";
        state.content = action.payload.content;
      },
      [asyncUpdateReactionBoardSlice.rejected]: (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      },
    }
  }
)

export const updateReactionBoardReducer = updateReactionBoardSlice.reducer
