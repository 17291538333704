import {Button} from "react-bootstrap";

const InputEmail = ({onChangeEmail, onClickButton, isMobile}) => {
  return (
    <div className="mt-4 mb-2">
      <div style={{fontWeight: "normal", fontSize: isMobile ? "15px" : "18px", marginBottom: "5px"}}>사용하실 이메일을 입력해주세요
      </div>
      <input style={{
        width: "80%",
        float: "left",
        fontSize: isMobile ? "13px" : "16px"
      }} onChange={onChangeEmail} type="text" className="form-control" id="email" placeholder="you@example.com"
             required/>
      <Button onClick={onClickButton}
              style={{
                width: "20%",
                backgroundColor: "#775ee2",
                fontSize: isMobile ? "13px" : "16px"
              }}
      > 확인
      </Button>
    </div>
  )
}
export default InputEmail;