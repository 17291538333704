import sty from "@emotion/styled";
import styled from "@emotion/styled";
import StyledDesktopWrapper from "../../components/wrapper/StyledDesktopWrapper";
import {Grid} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import useLocalStorage from "../../hooks/useLocalStorage";
import {asyncUpdateFreeBoardSlice} from "../../redux/board/updateFreeBoardSlice";
import {getFreeBoardDetailSlice} from "../../redux/board/freeBoardDetailSlice";
import {useParams} from "react-router";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

const FreeBoardUpdatePage = () => {

  const {boardId} = useParams();


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [contentData, setContent] = useState("")
  const [userInfo] = useLocalStorage("userInfo", []);
  const [titleData, setTitle] = useState("")
  const freeBoard = useSelector((store) => store.freeBoardDetailRequest);

  useEffect(() => {
    dispatch(getFreeBoardDetailSlice({boardId}))
    setTitle(freeBoard.content.title)
    setContent(freeBoard.content.content)
  }, []);


  const handleOnClick = () => {
    if (titleData.length === 0) {
      alert("제목을 입력해주세요 😭");
      return
    }

    if (contentData.length === 0) {
      alert("내용을 입력해주세요 😭");
      return;
    }


    (async () => {
      const isAvailable = await dispatch(asyncUpdateFreeBoardSlice({
        userId: userInfo['id'],
        content: contentData,
        title: titleData,
        boardId: boardId
      }))
      if (isAvailable === undefined) {
        alert("글작성에 실패했어요 😭");
      } else {
        navigate(`/board/free`);
      }
    })();
  }

  return (
    <div>
      <StyledDesktopWrapper>
        <BoardForm>
          <h4 style={{fontWeight: "bold"}}>📝 글변경하기</h4>
          <hr style={{width: "100%", marginTop: "20px", marginBottom: "40px"}}/>
          <input style={{
            width: "100%",
          }} type="text" className="form-control"
                 defaultValue={freeBoard.content.title}
                 onChange={e => setTitle(e.target.value)}
          />
          <br/>
          <Grid item xs={12}>
            <CKEditor
              editor={ClassicEditor}
              data={freeBoard.content.content}
              onReady={editor => {
                // You can store the "editor" and use when it is needed.
                console.log('댓글 등록 하기 ✍️', editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setContent(data)
              }}
              onBlur={(event, editor) => {
                console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                console.log('Focus.', editor);
              }}
            />
            <WritePageStyle
              onClick={handleOnClick}
              justifyContent={"center"}
              height={"50px"}
              style={{
                marginTop: "30px",
                width: "150px",
                alignItems: "center",
                background: "#775ee1",
                color: "white",
                fontSize: "25px",
                borderRadius: "20px",
                cursor: "pointer",
              }}
            >등록
            </WritePageStyle>
          </Grid>
        </BoardForm>
      </StyledDesktopWrapper>
    </div>
  )
}

export default FreeBoardUpdatePage


const WritePageStyle = styled.div`
  width: ${(props) => props.width};
  margin: 0 auto;
  display: flex;
  justify-content: ${(props) =>
  props.justifyContent ? props.justifyContent : "space-between"};
  height: ${(props) => props.height};
`;


const BoardForm = sty.div`
        width: 100%;
        max-width: 800px;
        padding: 60px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.50)
        `