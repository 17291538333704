import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {DESTINATION_DOMAIN_ADDRESS} from "../../utils/constants";

export const getFreeBoardSlice = createAsyncThunk(
  "freeBoard/page",
  async ({size, page}) => {
    return await axios
      .get(`${DESTINATION_DOMAIN_ADDRESS}/board?size=${size}&page=${page}`)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const freeBoardSlice = createSlice({
  name: "freeBoard",
  initialState: {
    content: [],
    status: null,
  },
  reducers: {},
  extraReducers: {
    [getFreeBoardSlice.pending]: (state, action) => {
      state.status = "loading";
    },
    [getFreeBoardSlice.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.content = action.payload;
    },
    [getFreeBoardSlice.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const freeBoardReducer = freeBoardSlice.reducer;
